.tabs {
  background: #270d6e;

  > * {
    cursor: pointer;
  }

  img {
    width: 40px;
    margin: 30px 10px;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }
}
