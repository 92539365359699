@import './variables.scss';

body,
html {
  margin: 0;
  padding: 0;

  color: $font-color;
  font-family: $font-standard;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  font-weight: 900;
  text-transform: uppercase;
  color: $color-primary;
  letter-spacing: 3px;
  margin-top: 0;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 26px;
  display: block;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 3px solid $color-primary;
}

.view {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  padding: 70px 25px;

  &:nth-child(odd),
  &.contact {
    background: $color-background-light;
  }

  &.header {
    background: $color-primary;
    color: #fff;
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text-block {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

a {
  color: #333;
}

.text-block {
  max-width: 580px;
  width: 100%;
  padding: 0 20px;
  text-align: justify;
  margin: 0 auto;
  font-weight: 600;
}

.label-wrapper {
  label {
    min-width: 40px;
    display: inline-block;
    color: $font-light;
  }
}

.field-wrapper {
  margin-bottom: 20px;

  label {
    margin: 10px 0 4px;
    display: block;
    font-size: $font-size-s;
  }
}
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  display: block;
  width: 100%;
  max-width: 350px;
  border: 1px solid $color-background-light;
  padding: 9px 15px;
  border-radius: 4px;
  font-family: $font-standard;
  color: $font-color;
  resize: none;
}

textarea {
  height: 160px;
}

button,
input[type='button'],
.button {
  background: $color-primary;
  color: #fff;
  display: block;
  width: 100%;
  max-width: 350px;
  text-align: center;
  padding: 9px 20px;
  font-size: $font-size-l;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  font-weight: normal;
}

.flex {
  display: flex;

  &.rows {
    flex-direction: row;
  }

  &.columns {
    flex-direction: column;
  }
}

@media screen and (min-width: 560px) {
  .view {
    padding: 50px;
  }
}

@media screen and (min-width: 720px) {
  .view {
    .row {
      flex-direction: row;
    }
  }
}
