@import '../../styles/variables.scss';

.header {
  text-align: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;

  img {
    margin: 20px auto;
    width: 80px;
  }
  
  h1, h2 {
    color: #fff;
    margin: 0;
  }

  h2 {
    text-transform: lowercase;
    letter-spacing: 24px;
    font-size: 30px;
    font-weight: 300;
    padding-left: 18px;
  }
}

.arrow-down {
  position: absolute;
  cursor: pointer;
  font-size: 42px;
  bottom: 20px;
  left: 50%;
  padding-top: 0;
  transform: translate(-50%,0);
  transition: bottom .2s ease;
  padding: 10px;

  &:hover {
    bottom: 20px;
    padding-top: 10px;
  }
}