@import '../../styles/variables.scss';

.project {
  .project-inner {
    max-width: 1240px;
    min-height: 400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    &.align-left {
      justify-content: flex-start;
    }
  }

  .project-info-box {
    background: #180059;
    color: #fff;
    padding: 40px;
    max-width: 500px;
    width: 100%;
  }

  h2,
  h3 {
    color: #fff;
  }

  h2 {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  }

  .button {
    text-decoration: none;
    border: 2px solid #fff;
    display: inline-block;
    width: auto;
    background: #180059;
  }
}
