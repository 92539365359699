.services {
  min-height: 100vh;
}

.services-icons {
  display: flex;
  max-width: 700px;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.service-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
  // min-width: 150px;
  // width: 100%;

  img {
    height: 70px;
    margin: 0 auto 20px;
  }
}

@media screen and (min-width: 760px) {
  .services-icons {
    flex-direction: row;
  }
  .service-wrapper {
    width: 30%;
  }
}
