$color-primary-light: #FAE386;
$color-primary: #F6C80E;
$color-highlight: #197ed4;
$color-background-light: #f5f6fa;
$color-error: #cb2b2b;
$color-orange: rgb(238, 156, 32);
$color-green: rgb(23, 167, 95);
$color-dark-blue: #023361;
$color-purple: #6b309b;

$color-lighter-gray: #efefef;
$color-light-gray: #b0b0b0;
$color-medium-gray: #a5a5a5;
$color-dark-gray: #525252;
$font-light: #888;
$font-color: #444;
$color-border-light: #d1d1d1;

$font-standard: 'Muli', sans-serif;;
$font-header: 'Muli', sans-serif;;

$font-size-xs: 10px;
$font-size-s: 13px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 18px;

$spacing-default: 15px;

$border-radius: 3px;

$default-shadow: 0 1px 6px -2px $color-dark-gray;