.text-block.contact-form {
  padding-top: 28px;
  width: 500px;
  max-width: 100%;
  min-width: 270px;
}

.social-link {
  display: block;
  margin-top: 10px;

  a {
    display: block;
    margin-top: 15px;

    i {
      margin-right: 10px;
    }
  }
}
